import { removeLoggedToken } from './setToken'

export default async function isAuthenticated(id) {
  const token =
    typeof window !== 'undefined' ? localStorage.getItem(`token-${id}`) : ''
  let validToken = false
  if (token) {
    try {
      const req = await fetch(
        `${process.env.GATSBY_SERVERLESS_BASE}/functions/user-login/checkToken`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${token}`,
          },
          body: JSON.stringify({}),
        }
      )
      validToken = !(req.status >= 400 && req.status < 600)
    } catch (err) {
      validToken = false
    }
  }
  if (!validToken) {
    removeLoggedToken(id)
  }

  return validToken
}
