import React from 'react'
import Layout from './src/components/Layout'
import './src/styles/global.css'
import { AppProvider } from './src/components/AppContext'

export function wrapPageElement({ element, props }) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Layout {...props}>{element}</Layout>
}

export function wrapRootElement({ element }) {
  return <AppProvider>{element}</AppProvider>
}
