export function setLoggedToken(t, id) {
  if (typeof window !== 'undefined') {
    localStorage.setItem(`token-${id}`, t)
  }
}

export function removeLoggedToken(id) {
  if (typeof window !== 'undefined') {
    localStorage.removeItem(`token-${id}`)
  }
}
