import React, { useState } from 'react'
import isAuthenticated from '../utils/isAuthenticated'

const AppContext = React.createContext()

async function checkTokenUser(cb) {
  const validToken = await isAuthenticated('user')
  cb(validToken)
}

export function AppProvider({ children }) {
  const [loggedUser, setLoggedUser] = useState(false)
  checkTokenUser(setLoggedUser)
  return (
    <AppContext.Provider
      value={{
        user: [loggedUser, setLoggedUser],
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export default AppContext
